<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Calcula comissão no pedido:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-4"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Calcula comissão apenas em pedidos, mas somente se o pedido não estiver vinculado a uma venda. <br>
                Não - Calcula comissão apenas em vendas, ocultando o quadro pedido.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  
                  class="pl-7 pb-2"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Sim - Calcula comissão apenas em pedidos, mas somente se o pedido não estiver vinculado a uma venda. <br>
                Não - Calcula comissão apenas em vendas, ocultando o quadro pedido.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>   
        <v-row
          no-gutters
        >
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="d-flex align-center"
          >
            Tipo de comissão:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Comissão fixa por vendedor. <br>
                Comissão por forma de pagamento.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="3"
            md="3"
            :cols="$vssWidth < 600 ? 4 : 8"
          >
            <v-select
              dense
              outlined
              :items="['Fixa', 'Forma de pagamento']"
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  class="pl-7 pb-6"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Comissão fixa por vendedor. <br>
                Comissão por forma de pagamento.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>  
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            CPF/CNPJ obrigatório na manutenção de colaborador:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-switch 
              inset
            />
          </v-col>
        </v-row>    
      </v-col>          
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>