var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%","flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{style:({borderBottom: '1px solid #ccc', width: '100%', height: '60px'})},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-row',{staticClass:"pt-2",style:({height:'88%'}),attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10","md":"10","cols":_vm.$vssWidth < 600 ? 8 : 8}},[_vm._v(" Calcula comissão no pedido: ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth < 600),expression:"$vssWidth < 600"}],staticClass:"pt-4",attrs:{"sm":"2","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-flex justify-center"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Sim - Calcula comissão apenas em pedidos, mas somente se o pedido não estiver vinculado a uma venda. "),_c('br'),_vm._v(" Não - Calcula comissão apenas em vendas, ocultando o quadro pedido. ")])])],1),_c('v-col',{attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-switch',{attrs:{"inset":""}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 1 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth >= 600),expression:"$vssWidth >= 600"}],staticClass:"pl-7 pb-2"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Sim - Calcula comissão apenas em pedidos, mas somente se o pedido não estiver vinculado a uma venda. "),_c('br'),_vm._v(" Não - Calcula comissão apenas em vendas, ocultando o quadro pedido. ")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"8","md":"8","cols":_vm.$vssWidth < 600 ? 6 : 8}},[_vm._v(" Tipo de comissão: ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth < 600),expression:"$vssWidth < 600"}],staticClass:"pt-2",attrs:{"sm":"2","md":"1","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-flex justify-center"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Comissão fixa por vendedor. "),_c('br'),_vm._v(" Comissão por forma de pagamento. ")])])],1),_c('v-col',{attrs:{"sm":"3","md":"3","cols":_vm.$vssWidth < 600 ? 4 : 8}},[_c('v-select',{attrs:{"dense":"","outlined":"","items":['Fixa', 'Forma de pagamento']}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"1","md":"1","cols":_vm.$vssWidth < 600 ? 1 : 8}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$vssWidth >= 600),expression:"$vssWidth >= 600"}],staticClass:"pl-7 pb-6"},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(" Comissão fixa por vendedor. "),_c('br'),_vm._v(" Comissão por forma de pagamento. ")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10","md":"10","cols":_vm.$vssWidth < 600 ? 10 : 8}},[_vm._v(" CPF/CNPJ obrigatório na manutenção de colaborador: ")]),_c('v-col',{staticClass:"pt-2",attrs:{"sm":"2","md":"2","cols":_vm.$vssWidth < 600 ? 2 : 8}},[_c('v-switch',{attrs:{"inset":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }